import {
    EnvironmentOutlined,
    HeartOutlined,
    MailOutlined,
    StarOutlined,
    TeamOutlined,
    UserAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Alert, AutoComplete, Empty, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { memo, ReactElement, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import './index.less';

interface IPersonOption extends DefaultOptionType {
    person?: IPerson;
}

interface IProps {
    getPersons: (value: string) => Promise<IPerson[] | undefined>;
    selectPerson: (person?: IPerson) => Promise<void>;
    onClearPerson: () => void;
    placeholder?: string;
    isSearching: boolean;
    value?: IPerson;
}

function ValidateEmail(mail: string) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

const PersonsSearchComponent = (props: IProps): ReactElement | null => {
    const [options, setOptions] = useState<IPersonOption[]>([]);
    const [name, setName] = useState<string | undefined>(props.value?.email);

    const debounced = useDebouncedCallback(
        async (value: string) => {
            let personsIn = await props.getPersons(value);
            if ((!personsIn || personsIn?.length === 0) && ValidateEmail(value)) {
                personsIn = [{
                    type: 'Email',
                    email: value,
                    addresses: [],
                }];
            }
            setOptions(getPersonOptions(personsIn || []));
        }, 400,
    );

    const onSearchPersons = async (value: string) => {
        debounced(value)?.catch((err: Error) => {
            console.log(err);
        });
    };

    const onChangePerson = async (nameIn: string) => {
        if (nameIn === '') {
            setName(undefined);
        }
        else {
            setName(nameIn);
        }
    };

    const onSelectPerson = async (_value: string, item?: IPersonOption) => {
        await props.selectPerson(item?.person);
        setName(getPersonName(item?.person));
    };

    const onClearPerson = async () => {
        await props.selectPerson(undefined);
        props.onClearPerson();
    };

    const getPersonName = (person?: IPerson): string => {
        const { firstName, lastName, email } = person || {};
        if (firstName || lastName) return `${firstName ?? ''} ${lastName ?? ''}`.trim();
        return `${email ?? ''}`.trim();
    };

    const getPersonOptions = (personsIn: IPerson[]): IPersonOption[] => {
        return personsIn.map((person: IPerson, index: number) => {
            const { id, civility, firstName, lastName, email, name, speciality, subSpeciality } = person;

            const addressElement = person.city || person.postalCode ? <div>
                <div><EnvironmentOutlined /></div>
                <div>{person.postalCode} {person.city}</div>
            </div> : undefined;

            const specialityElement = speciality ? <div>
                <div><StarOutlined /></div>
                <div>{speciality}</div>
            </div> : undefined;

            const subSpecialityElement = subSpeciality ? <div>
                <div><HeartOutlined /></div>
                <div>{subSpeciality}</div>
            </div> : undefined;

            let typeElement = <UserAddOutlined />;
            switch (person.type) {
                case 'UserEntity':
                    typeElement = <UserOutlined />;
                    break;
                case 'ContactEntity':
                    typeElement = <TeamOutlined />;
                    break;
                case 'Email':
                    typeElement = <MailOutlined />;
                    break;
            }

            return {
                key: 'p' + index,
                value: id || email,
                label: <div data-id={id}>
                    <div className="list-item-main-info">
                        <div>{name ?? `${civility ?? ''} ${firstName ?? ''} ${lastName ?? ''}`}</div>
                        {typeElement}
                    </div>
                    <div className="list-item-secondary-info">
                        {addressElement}
                        {specialityElement}
                        {subSpecialityElement}
                    </div>
                </div>,
                person,
            };
        });
    };

    const renderDropdown = (menu: ReactElement): ReactElement => {
        return props.isSearching ?
            <div className='search-loading-indicator'><Spin tip='Loading' /></div> :
            <>
                {menu}
                <Alert className="search-warning" type="warning" message="Affinez votre recherche pour afficher plus de résultats" />
            </>;
    };

    return <AutoComplete
        className="persons-search"
        showSearch
        placeholder={props.placeholder ?? 'Veuillez saisir un nom ou un prénom'}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={onSearchPersons}
        onChange={onChangePerson}
        onSelect={onSelectPerson}
        notFoundContent={props.isSearching ? <div className='search-loading-indicator'><Spin tip='Loading' /></div> : <Empty description={<span>Pas de résultat</span>} />}
        allowClear
        onClear={onClearPerson}
        options={options}
        value={name}
        dropdownRender={renderDropdown}
    />;
};

export default memo(PersonsSearchComponent);
