import { Button, Divider, Modal, Typography } from 'antd';
import { ReactElement, useState } from 'react';
import { Subject } from 'rxjs';
import { renderMessage, useMessage } from 'services/helpers';
import { useSessionActions } from '../../redux/actions/session.action';
import PatientSearch from '../search/patients';
import { VitaleInstallButton } from '../vitale/install-button';
import { VitaleButton } from '../vitale/vitale-button';
import './index.less';
import { PatientForm } from './patient-form';

interface IProps {
    validate: (patient: IPatientParams) => void;
    onHide: () => void;
    onSelectPatient: (patient: IPatient) => void;
}

const PatientCreationModal = (props: IProps): ReactElement | null => {
    const [message, setMessage] = useMessage();
    const [patient, setPatient] = useState<IPatientParams | undefined>();
    const { isConnected } = useSessionActions();

    const onValidate$ = new Subject<void>();

    const onValidate = async () => {
        onValidate$.next();
    };

    const validate = (patient: IPatientParams) => {
        props.validate(patient);
        hide();
    };

    const hide = () => {
        props.onHide();
    };

    return <Modal
        className="patient-modal"
        title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Nouveau patient</span>
            <VitaleButton onSelect={patient => {
                setPatient(patient);
            }} />
        </div>}
        open={true}
        closable={false}
        onOk={onValidate}
        onCancel={hide}
        width="60rem"
        footer={[
            <Button key="back" onClick={hide}>
                Annuler
            </Button>,
            <Button key="submit" type="primary" onClick={onValidate} >
                Valider
            </Button>,
        ]}
    >
        {renderMessage(message)}
        {<VitaleInstallButton />}
        {isConnected() ||
            <>
                <PatientSearch placeholder={'Recherche de patient'} onChange={patient => {
                    patient && props.onSelectPatient(patient);
                }} />
                <Divider><Typography.Text type={'secondary'}>ou</Typography.Text></Divider>
            </>
        }
        <PatientForm
            setMessage={setMessage}
            patient={patient}
            onValidate$={onValidate$}
            validate={validate}
            setPatient={setPatient}
            disabled={false}
        />
    </Modal >;
};

export default PatientCreationModal;
