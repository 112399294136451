import { DeleteOutlined, FileAddOutlined, UploadOutlined, UserAddOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, List, Tag, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PersonsSearch from 'components/search/persons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { memo, ReactElement, useEffect, useState } from 'react';
import { renderBirthDate, renderMessage } from 'services/helpers';
import { renderDocumentPreviewModal } from '../document/upload-modal';
import DocumentsModal from './documents-modal';
import './index.less';
import { VitaleButton } from 'components/vitale/vitale-button';
import PatientSearch from 'components/search/patients';
import PatientModal from '../../patient-modal/patient-modal';
import PatientCreationModal from '../../patient-modal/patient-creation-modal';

interface IProps {
    message?: IMessage;
    isAuthenticated: boolean;
    validate: (values: IExpertiseParams) => void;
    selectDocuments: (documents: IDocument[]) => void;
    deleteDocument: (document: IDocument) => void;
    documents: IDocument[];
    addPatient: (patient: IPatientParams) => void;
    removePatient: () => void;
    patient: IPatientParams | undefined;
    saveDocument: (document: IDocument) => void;
}

const ExpertisesRequestComponent = (props: IProps): ReactElement => {
    const [form] = Form.useForm<IExpertiseParams>();
    const [canRequest, setCanRequest] = useState<boolean>(true);
    const [isDocumentsModalShown, setDocumentsModalShown] = useState<boolean>(false);

    const [patientModal, setPatientModal] = useState<ReactElement | null>(null);

    const validate = async () => {
        setCanRequest(false);

        await form.validateFields().then(async (values: IExpertiseParams) => {
            props.validate(values);
        }).catch(err => {
            setCanRequest(true);
            console.log(err);
        });
    };

    const showPatientCreationModal = () => {
        setPatientModal(<PatientCreationModal
            onHide={hidePatientModal}
            validate={addPatient}
            onSelectPatient={addPatient}
        />);
    };

    const showPatientModal = () => {
        setPatientModal(<PatientModal
            onHide={hidePatientModal}
            show={true}
            validate={addPatient}
            values={props.patient}
            readonly={props.patient?.fromVitale}
        />);
    };

    const hidePatientModal = () => {
        setPatientModal(null);
    };

    const addPatient = (infoPatient: IPatientParams) => {
        props.addPatient(infoPatient);
        hidePatientModal();
    };

    const showDocumentsModal = () => {
        setDocumentsModalShown(true);
    };

    const hideDocumentsModal = () => {
        setDocumentsModalShown(false);
    };

    const onSelectDocuments = (documents: IDocument[]) => {
        props.selectDocuments(documents);
    };

    const onDeleteDocument = (document: IDocument) => {
        props.deleteDocument(document);
    };

    const onUploadDocument = (uploadRequestOptions: UploadRequestOption) => {
        renderDocumentPreviewModal({
            uploadRequestOptions,
            saveDocument: props.saveDocument,
        });
    };

    useEffect(() => {
        setCanRequest(true);
    }, [props.message]);

    return <Card
        className="expertise-request"
        bordered={false}
        title="Demande d'expertise">
        <Form
            className="form vertical"
            layout="vertical"
            form={form}
        >
            {renderMessage(props.message)}
            <div>
                <div className="inline">
                    <Form.Item label="Destinataire" name="recipient" className={'form-item-horizontal'}
                        rules={[{
                            required: true,
                            message: 'Veuillez saisir le destinataire',
                        }]}>
                        <PersonsSearch
                            placeholder="Recherche par nom, prénom, adresse mail, ville, code postal, spécialité ou sous-spécialité"
                        />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prev, cur) => cur.recipient !== prev.recipient}>
                        {({getFieldValue}) => {
                            const r = getFieldValue('recipient');
                            if(r && [
                                r.firstName,
                                r.lastName,
                            ].filter(Boolean).length === 0) {
                                return <Form.Item label="A l'attention de"
                                    className={'form-item-horizontal'} name="to" rules={[{
                                        required: true,
                                        message: 'Veuillez saisir le nom du destinataire',
                                    }]}>
                                    <Input/>
                                </Form.Item>;
                            }
                        }}
                    </Form.Item>
                    {!props.patient ?
                        <Form.Item label="Patient" className={'form-item-horizontal'}>
                            <div className={'patient-block'}>
                                <PatientSearch
                                    placeholder="Recherche par nom, prénom, INS ou date de naissance"
                                    onChange={(patient) => {
                                        patient && addPatient(patient);
                                    }}
                                />
                                <Button onClick={showPatientCreationModal} title={'Nouveau patient'}>
                                    <UserAddOutlined color={'white'}/>
                                </Button>
                                <VitaleButton onSelect={patient => {
                                    addPatient(patient);
                                }}/>
                            </div>
                        </Form.Item>
                        :
                        <div className="ant-form-item form-item-horizontal">
                            <div className="ant-row ant-form-item-row">
                                <div className="ant-col ant-form-item-label"><label>Patient</label></div>
                                <Tag color="blue" className="selected-patient" closable onClick={showPatientModal}
                                    onClose={props.removePatient}>
                                    <span>
                                        {[props.patient.lastName, props.patient.firstName].filter(v => !!v).join(' ')}, {renderBirthDate(props.patient.birthDate)}
                                    </span>
                                </Tag>
                            </div>
                        </div>
                    }

                    <Form.Item label="Question" name="subject"
                        rules={[{
                            required: true,
                            message: 'Veuillez saisir le sujet de la demande d\'expertise',
                        }]}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 10 }} placeholder="Ecrivez votre demande..."/>
                    </Form.Item>
                    {(props.documents.length > 0) && <Form.Item label="Documents" className="documents">
                        <List
                            itemLayout="horizontal"
                            dataSource={props.documents}
                            renderItem={(document: IDocument, index: number) => {
                                const { name } = document;

                                return (
                                    <List.Item
                                        actions={[
                                            <Button
                                                onClick={() => onDeleteDocument(document)}
                                                className="delete"
                                                type="link"
                                                size="small"
                                                key={'d' + index}
                                                icon={<DeleteOutlined/>}
                                            />,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            title={
                                                <div className="list-item-main-info">
                                                    <div>{name}</div>
                                                </div>
                                            }/>
                                    </List.Item>
                                );
                            }}
                            locale={{ emptyText: 'Aucun document' }}
                        />
                    </Form.Item>}
                    {
                        props.isAuthenticated ?
                            < Form.Item label="Ajouter des documents..." valuePropName="fileList" className="attachments">
                                <Button onClick={showDocumentsModal} icon={<FileAddOutlined />}>
                                    Depuis mes documents
                                </Button>
                                <Upload className="upload" showUploadList={false} customRequest={onUploadDocument}>
                                    <Button
                                        icon={<UploadOutlined />}>
                                        Depuis mon poste
                                    </Button>
                                </Upload>
                            </Form.Item>
                            : null
                    }
                </div>
            </div>

            <Form.Item className="actions">
                <Alert
                    message="En continuant, vous confirmez avoir recueilli le consentement du patient."
                    type="warning"
                    action={
                        <Button
                            type="primary"
                            onClick={validate}
                            disabled={!canRequest}
                        >
                            Envoyer
                        </Button>
                    }
                />
            </Form.Item>
        </Form>
        {patientModal}
        <DocumentsModal
            onHide={hideDocumentsModal}
            show={isDocumentsModalShown}
            validate={onSelectDocuments}
        />

    </Card>;
};

export default memo(ExpertisesRequestComponent);
