const pages: Record<string, IPage> = {
    login: { path: '/' },
    registration: { path: '/registration' },
    registrationWithValidation: { path: '/registration/validation' },
    reset: { path: '/reset' },
    passwordReset: { path: '/password-reset' },
    account: { path: '/account' },
    billingAddress: { path: '/billing-address' },
    email: { path: '/email' },
    security: { path: '/security' },
    notification: { path: '/notification/:id' },
    myDocuments: { path: '/documents/my' },
    documentsSharedWithMe: { path: '/documents/shared' },
    experts: { path: '/experts' },
    patients: { path: '/patients' },
    billing: { path: '/billing' },
    invoices: { path: '/invoices' },
    paymentMethods: { path: '/payment-methods' },
    groups: { path: '/groups' },
    myGroups: { path: '/my-groups' },
    myGroup: { path: '/my-groups/:id' },
    groupRequests: { path: '/group-requests' },
    groupInvitations: { path: '/group-invitations' },
    tasks: { path: '/tasks' },
    expertises: { path: '/expertises/:id' },
    expertisesSent: { path: '/expertises/sent' },
    expertisesReceived: { path: '/expertises/received' },
    expertisesRequest: { path: '/expertises' },
    expertisesSentChat: { path: '/expertises/sent/:id/chat' },
    expertisesReceivedChat: { path: '/expertises/received/:id/chat' },
    expertisesEmpty: { path: '/expertises/empty' },
    expertisesPlaceholder: { path: '/expertises/placeholder' },
    accountsAdministration: { path: '/accounts/admin' },
    proSanteConnectCallback: { path: '/pro-sante-connect/callback' },
    proSanteConnectLogin: { path: '/pro-sante-connect/login/:pscToken' },
    contacts: { path: '/contacts' },
    embeddedExpertise: { path: '/embedded/expertise' },
};

export default pages;
