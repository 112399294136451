import pages from 'pages';
import { memo, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useExpertiseActions } from 'redux/actions/expertise.action';
import { usePatientActions } from 'redux/actions/patient.action';
import { useMessage } from 'services/helpers';
import { AppState } from '../../../redux/reducers';
import ExpertisesRequestComponent from './component';

const isPatient = (patient: IPatientParams): patient is IPatient => {
    return 'id' in patient;
};

const ExpertisesRequest = (): ReactElement => {
    const navigate = useNavigate();
    const [message, setMessage] = useMessage();
    const [infoPatient, setInfoPatient] = useState<IPatientParams | IPatient>();
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const { createPatient } = usePatientActions();
    const { createExpertiseFromPerson, createExpertiseFromEmail, linkDocumentToExpertise, sendMessageToExpertise } = useExpertiseActions();

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const isAuthenticated = !!mapStateToObj.session.user;

    const onValidate = async (values: IExpertiseParams) => {
        try {
            let patient = undefined;

            if (infoPatient) {
                if (isPatient(infoPatient)) {
                    patient = infoPatient;
                } else {
                    patient = await createPatient(infoPatient ? infoPatient : {} as IPatientParams).catch((err: Error) => {
                        throw err;
                    });
                }
            }

            values.patient = patient;
            let expertise: IExpertise;
            console.log(values.recipient);
            if (values.recipient && ['GroupEntity', 'UserEntity', 'RppsEntity'].includes(values.recipient.type)) {
                expertise = await createExpertiseFromPerson(values).catch((err: Error) => {
                    throw err;
                });
            }
            else {
                expertise = await createExpertiseFromEmail(values).catch((err: Error) => {
                    throw err;
                });
            }
            const { id } = expertise;

            const messageParam: IExpertiseMessageParams = {
                content: expertise.subject,
            };
            await sendMessageToExpertise(id, messageParam)
                .catch((err: Error) => {
                    throw err;
                });

            for (const document of documents) {
                await linkDocumentToExpertise(document.id, id);
            }

            navigate(pages.expertisesSentChat.path.replace(':id', String(id)));
        } catch (err) {
            setMessage(err);
        }
    };

    const onSelectDocuments = (documentsIn: IDocument[]) => {
        setDocuments(documentsIn);
    };

    const onSaveDocument = (document: IDocument) => {
        if (!documents.find((documentIn: IDocument) => documentIn.id === document.id)) {
            setDocuments([
                ...documents,
                document,
            ]);
        }
    };

    const onDeleteDocument = (document: IDocument) => {
        setDocuments(documents.filter((documentIn: IDocument) => documentIn.id !== document.id));
    };

    const addPatient = (patient: IPatientParams) => {
        setInfoPatient(patient);
    };

    const removePatient = () => {
        setInfoPatient(undefined);
    };

    return <ExpertisesRequestComponent
        message={message}
        isAuthenticated={isAuthenticated}
        validate={onValidate}
        selectDocuments={onSelectDocuments}
        deleteDocument={onDeleteDocument}
        saveDocument={onSaveDocument}
        documents={documents}
        addPatient={addPatient}
        patient={infoPatient}
        removePatient={removePatient}
    />;
};

export default memo(ExpertisesRequest);
