export interface IRoute {
    method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
    path: string;
}

export const routes: Record<string, IRoute> = {
    archiveExpertise: {
        method: 'DELETE',
        path: '/expertises/{expertiseId}',
    },
    cancelGroupInvitation: {
        method: 'DELETE',
        path: '/invites/{inviteId}',
    },
    createExpertiseFromEmail: {
        method: 'POST',
        path: '/expertises/fromEmail',
    },
    createExpertiseFromPerson: {
        method: 'POST',
        path: '/expertises',
    },
    createTemporaryExpertise: {
        method: 'POST',
        path: '/expertises/temporary',
    },
    linkTemporaryExpertise: {
        method: 'PATCH',
        path: '/expertises/temporary/{uuid}',
    },
    createGroup: {
        method: 'POST',
        path: '/groups',
    },
    createPatient: {
        method: 'POST',
        path: '/patients',
    },
    createPatients: {
        method: 'POST',
        path: '/patients/batch',
    },
    deleteDocument: {
        method: 'DELETE',
        path: '/documents/{documentId}/group/{groupId}',
    },
    deleteDocumentFromExpertise: {
        method: 'DELETE',
        path: '/expertises/{expertiseId}/documents/{documentId}',
    },
    exportPdf: {
        method: 'GET',
        path: '/expertises/{expertiseId}/export?format=pdf',
    },
    getDocument: {
        method: 'GET',
        path: '/documents/{documentId}',
    },
    getDocumentFromExpertise: {
        method: 'GET',
        path: '/expertises/{expertiseId}/documents/{documentId}',
    },
    getDocumentFromExpertiseMetadata: {
        method: 'GET',
        path: '/expertises/{expertiseId}/documents/{documentId}/metadata',
    },
    getDocumentMetadata: {
        method: 'GET',
        path: '/documents/{documentId}/metadata',
    },
    getDocuments: {
        method: 'GET',
        path: '/documents',
    },
    getExpertise: {
        method: 'GET',
        path: '/expertises/{expertiseId}',
    },
    getExpertises: {
        method: 'GET',
        path: '/expertises',
    },
    getGroup: {
        method: 'GET',
        path: '/groups/{groupId}',
    },
    getGroupInvitations: {
        method: 'GET',
        path: '/invites',
    },
    getGroupRequests: {
        method: 'GET',
        path: '/requests',
    },
    getGroups: {
        method: 'GET',
        path: '/groups',
    },
    getMessageFromExpertise: {
        method: 'GET',
        path: '/expertises/{expertiseId}/messages',
    },
    getNotifications: {
        method: 'GET',
        path: '/users/{userId}/notifications',
    },
    getNotification: {
        method: 'GET',
        path: '/notifications/{notificationId}',
    },
    getPersons: {
        method: 'GET',
        path: '/persons',
    },
    getPatients: {
        method: 'GET',
        path: '/patients',
    },
    getProfile: {
        method: 'GET',
        path: '/users/profile',
    },
    getRpps: {
        method: 'GET',
        path: '/rpps/{rpps}',
    },
    getUser: {
        method: 'GET',
        path: '/users/{userId}',
    },
    getUsers: {
        method: 'GET',
        path: '/users',
    },
    getContacts: {
        method: 'GET',
        path: '/contacts',
    },
    getInvoices: {
        method: 'GET',
        path: '/invoices',
    },
    getInvoicesCsv: {
        method: 'GET',
        path: '/invoices/export',
    },
    printInvoice: {
        method: 'GET',
        path: '/invoices/{expertiseId}',
    },
    getUserInvoices: {
        method: 'GET',
        path: '/my/invoices',
    },
    refreshUserInvoice: {
        method: 'POST',
        path: '/stripe/invoices/{invoiceId}/refresh',
    },
    generateUserInvoices: {
        method: 'POST',
        path: '/my/invoices/generate',
    },
    setupPayment: {
        method: 'POST',
        path: '/my/setup-payment',
    },
    getPaymentMethods: {
        method: 'GET',
        path: '/my/payment-methods',
    },
    getBillingAddress: {
        method: 'GET',
        path: '/my/billing-address',
    },
    updateBillingAddress: {
        method: 'PUT',
        path: '/my/billing-address',
    },
    deleteContact: {
        method: 'DELETE',
        path: '/contacts/{contactId}',
    },
    uploadContact: {
        method: 'POST',
        path: '/contacts',
    },
    getConstants: {
        method: 'GET',
        path: '/config/constants',
    },
    invitePersonInGroup: {
        method: 'POST',
        path: '/invites/group/{groupId}',
    },
    leaveGroup: {
        method: 'DELETE',
        path: '/groups/{groupId}',
    },
    linkDocumentToExpertise: {
        method: 'POST',
        path: '/expertises/{expertiseId}/documents/{documentId}',
    },
    listDocumentsFromExpertise: {
        method: 'GET',
        path: '/expertises/{expertiseId}/documents',
    },
    login: {
        method: 'POST',
        path: '/authentication/login',
    },
    newToken: {
        method: 'POST',
        path: '/authentication/token',
    },
    proSanteConnectLogin: {
        method: 'POST',
        path: '/pro-sante-connect/login',
    },
    refreshAccessToken: {
        method: 'POST',
        path: '/authentication/session/refresh',
    },
    register: {
        method: 'POST',
        path: '/users',
    },
    removeUserFromGroup: {
        method: 'DELETE',
        path: '/groups/{groupId}/member/{userId}',
    },
    requestEmailCode: {
        method: 'POST',
        path: '/users/email',
    },
    requestGroupJoining: {
        method: 'POST',
        path: '/requests/group/{groupId}',
    },
    requestPasswordReset: {
        method: 'POST',
        path: '/users/password/reset',
    },
    resetPassword: {
        method: 'PATCH',
        path: '/users/password/reset',
    },
    searchRpps: {
        method: 'GET',
        path: '/rpps',
    },
    sendMessageToExpertise: {
        method: 'POST',
        path: '/expertises/{expertiseId}/messages',
    },
    sendToken: {
        method: 'POST',
        path: '/authentication/token',
    },
    setMessageAsRead: {
        method: 'PATCH',
        path: '/expertises/{expertiseId}/messages/{messageId}',
    },
    shareDocumentWithEmail: {
        method: 'POST',
        path: '/documents/{documentId}/external_user',
    },
    shareDocumentWithGroup: {
        method: 'POST',
        path: '/documents/{documentId}/group/{groupId}',
    },
    updateAccountSecurity: {
        method: 'PATCH',
        path: '/users/profile/security',
    },
    updateDocument: {
        method: 'PATCH',
        path: '/documents/{documentId}/metadata',
    },
    updateExpertise: {
        method: 'PATCH',
        path: '/expertises/{expertiseId}',
    },
    updateGroup: {
        method: 'PATCH',
        path: '/groups/{groupId}',
    },
    updateGroupInvitation: {
        method: 'PATCH',
        path: '/invites/{inviteId}',
    },
    updatePassword: {
        method: 'PATCH',
        path: '/users/profile/security',
    },
    updatePatient: {
        method: 'PATCH',
        path: '/patients/{patientId}',
    },
    updateProfile: {
        method: 'PATCH',
        path: '/users/profile',
    },
    updateProfileStatus: {
        method: 'PATCH',
        path: '/users/profile/{userId}/status',
    },
    uploadDocument: {
        method: 'POST',
        path: '/documents',
    },
    validateToken: {
        method: 'PUT',
        path: '/users/email/verify',
    },
    verifyAccessToken: {
        method: 'GET',
        path: '/authentication/session/verify',
    },
    verifyToken: {
        method: 'GET',
        path: '/authentication/token/{token}/verify',
    },
};
