import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, DatePicker, DatePickerProps, Form, Input, Upload, UploadFile } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import TextArea from 'antd/lib/input/TextArea';
import PersonsSearch from 'components/search/persons';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { renderMessage, useMessage } from 'services/helpers';
import pages from '../../../pages';
import { useExpertiseActions } from '../../../redux/actions/expertise.action';

export const EmbeddedExpertise = (): ReactElement => {
    const navigate = useNavigate();
    const [form] = Form.useForm<IExpertiseForm>();
    const [message, setMessage] = useMessage();
    const [canRequest, setCanRequest] = useState<boolean>(true);
    const [patientModal, setPatientModal] = useState<ReactElement | null>(null);
    const { createTemporaryExpertise } = useExpertiseActions();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        window.Tawk_API.onLoad = function () {
            window.Tawk_API.hideWidget();
        };
    }, []);

    const validate = async () => {
        await form.validateFields()
            .then((values: IExpertiseForm) => {
                const tmpExpertise: ITemporaryExpertiseParams = {
                    email: values.email,
                    subject: values.subject,
                    lastName: values.lastName,
                    firstName: values.firstName,
                    birthDate: values.birthDate,
                    searchableIdentityId: values.recipient?.id,
                    documents: fileList,
                };

                return createTemporaryExpertise(tmpExpertise);
            })
            .then((temporaryExpertise) => {
                window.parent.location.href = `${pages.registration.path}?temporaryExpertiseId=${temporaryExpertise.id}`;
            })
            .catch((err) => {
                setMessage(err.message);
            });
    };

    const onDatePickerChangeBirthDate: DatePickerProps['onChange'] = (date: moment.Moment | null) => {
        const birthDateIn = moment(date, 'DD/MM/YYYY', true);
        if (birthDateIn.isValid()) {
            form.setFieldsValue({
                birthDate: birthDateIn,
            });

        }
    };

    return <Card
        className="expertise-request"
        bordered={false}
        title="Demande d'avis">
        <Form
            className="form vertical"
            layout="vertical"
            form={form}
        >
            {renderMessage(message)}
            <div>
                <div className="inline">
                    <Form.Item label="Destinataire" name="recipient" className={'form-item-horizontal'}
                        rules={[{
                            required: true,
                            message: 'Veuillez saisir le destinataire',
                        }]}>
                        <PersonsSearch
                            placeholder="Recherche par nom et prénom"
                        />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prev, cur) => cur.recipient !== prev.recipient}>
                        {({ getFieldValue }) => {
                            const r = getFieldValue('recipient');
                            if (r && [
                                r.firstName,
                                r.lastName,
                            ].filter(Boolean).length === 0) {
                                return <Form.Item label="A l'attention de"
                                    className={'form-item-horizontal'} name="to" rules={[{
                                        required: true,
                                        message: 'Veuillez saisir le nom du destinataire',
                                    }]}>
                                    <Input />
                                </Form.Item>;
                            }
                        }}
                    </Form.Item>
                    <Form.Item
                        label="Patient"
                        className={'form-item-horizontal'}
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Item
                            name="lastName"
                            style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
                        >
                            <Input placeholder='Nom' />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}
                        >
                            <Input placeholder='Prénom' />
                        </Form.Item>
                        <Form.Item
                            name="birthDate" className="patient-modal-date"
                            style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
                        >
                            <DatePicker
                                allowClear={true}
                                locale={locale}
                                size="small"
                                format="DD/MM/YYYY"
                                onChange={onDatePickerChangeBirthDate}
                                placeholder="Date de naissance"
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Question" name="subject"
                        rules={[{
                            required: true,
                            message: 'Veuillez saisir le sujet de la demande d\'expertise',
                        }]}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 10 }} placeholder="Ecrivez votre demande..." />
                    </Form.Item>
                    <Form.Item label="Ajouter un document..." valuePropName="fileList" className="attachments">
                        <Upload
                            className="upload"
                            showUploadList={true}
                            onRemove={(file) => {
                                const index = fileList.indexOf(file);
                                const newFileList = fileList.slice();
                                newFileList.splice(index, 1);
                                setFileList(newFileList);
                            }}
                            beforeUpload={(file) => {
                                setFileList([...fileList, file]);

                                return false;
                            }}
                            fileList={fileList}
                        >
                            <Button
                                icon={<UploadOutlined />}>
                                Depuis mon poste
                            </Button>
                        </Upload>
                    </Form.Item>
                </div>
            </div>

            <Form.Item className="actions">
                <Alert
                    message="En continuant, vous confirmez avoir recueilli le consentement du patient."
                    type="warning"
                    action={
                        <Button
                            type="primary"
                            onClick={validate}
                            disabled={!canRequest}
                        >
                            Envoyer
                        </Button>
                    }
                />
            </Form.Item>
        </Form>
        {patientModal}
    </Card>;
};
