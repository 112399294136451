import pages from 'pages';
import { memo, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppActions } from 'redux/actions/app.action';
import { useDocumentActions } from 'redux/actions/document.action';
import { useSessionActions } from 'redux/actions/session.action';
import { AppState } from 'redux/reducers';
import { DefaultCivility, isUploadedDocument, useMessage } from 'services/helpers';
import { useExpertiseActions } from '../../../../redux/actions/expertise.action';
import RegisterComponent from './component';

export enum Step {
    RppsSearch,
    InformationFilling,
    CodesValidation,
    CpsUpload,
}

const StepsWorkflow: Record<Step, Step | undefined> = {
    [Step.RppsSearch]: Step.InformationFilling,
    [Step.InformationFilling]: Step.CodesValidation,
    [Step.CodesValidation]: Step.CpsUpload,
    [Step.CpsUpload]: undefined,
};

export interface ICodes {
    emailCode: string;
}

const Register = (): ReactElement | null => {
    const { pathname } = useLocation();
    const { register, login, completeAccount, verifyCode, updateSession, getRedirectUrl } = useSessionActions();
    const { getDocument } = useDocumentActions();
    const navigate = useNavigate();
    const [message, setMessage] = useMessage();
    const [user, setUser] = useState<IUser>();
    const [fromCredentials, setFromCredentials] = useState(false);
    const [step, setStep] = useState<Step>(Step.RppsSearch);
    const [cpsPhoto, setCpsPhoto] = useState<IDocumentMeta>();
    const [cps, setCps] = useState<IDocument>();
    const { constants } = useAppActions();
    const { linkTemporaryExpertise } = useExpertiseActions();
    const [temporaryExpertiseId, setTemporaryExpertiseId] = useState<string | null>(null);
    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    useEffect(() => {
        setMessage(undefined);
        const sessionUser = mapStateToObj.session.user;
        if (!fromCredentials) {
            setUser(sessionUser);
        }

        const queryParameters = new URLSearchParams(window.location.search);
        setTemporaryExpertiseId(queryParameters.get('temporaryExpertiseId'));

        if (pathname === pages.registrationWithValidation.path) {
            setStep(Step.CodesValidation);
        }
    }, [pathname]);

    useEffect(() => {
        if (temporaryExpertiseId) {
            setMessage('Afin de traiter votre demande d\'avis, nous devons vérifier que vous êtes un porfessionnel de santé. Merci de vous identifier en poursuivant les étapes ci-dessous.', 'info');
        }
    }, [temporaryExpertiseId]);

    const onFillInformation = async (credentials: IRegisterParams) => {
        if (!user) return;
        try {
            setFromCredentials(true);
            setMessage(undefined);

            const { email, password, passwordConfirmation } = credentials;

            if (password !== passwordConfirmation) {
                throw new Error('Les mots de passe ne correspondent pas');
            }

            await register({ ...credentials, rpps: user.rpps }).catch((err: Error) => {
                throw err;
            });

            setUser({
                ...user,
                email,
                password,
                type: 'UserEntity',
            });

            goNextStep();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onValidateCodes = async (codesIn: ICodes) => {
        try {
            setMessage(undefined);
            if (!user) {
                throw new Error('User not defined');
            }

            if (fromCredentials) {
                const { email, password } = user;
                if (!email || !password) {
                    throw new Error('Invalid credentials');
                }

                await validateCodesFromCredentials(codesIn, {
                    email,
                    password,
                }).catch((err: Error) => {
                    throw err;
                });
            }
            else {
                setFromCredentials(false);
                await validateCodesFromSession(codesIn, mapStateToObj.session).catch((err: Error) => {
                    throw err;
                });
            }

            goNextStep();
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onSearchRpps = (userIn: IUser) => {
        setUser(userIn);
        goNextStep();
    };

    const onComplete = async () => {
        try {
            if (!user) {
                throw new Error('Undefined user');
            }

            const { rpps, knowledgeLabel, professionLabel, civility, addresses, firstName, lastName } = user;

            const account = {
                ...user,
                rpps,
                civility: civility || DefaultCivility,
                speciality: professionLabel,
                subSpeciality: knowledgeLabel,
                firstName: firstName ?? '',
                lastName: lastName ?? '',
                addresses,
            } as IAccountParams;

            await completeAccount({
                ...account,
                idCard: cps?.id,
            })
                .then(async () => {
                    if (temporaryExpertiseId) {
                        return linkTemporaryExpertise(temporaryExpertiseId);
                    }
                })
                .catch((err: Error) => {
                    throw err;
                });

            navigate(getRedirectUrl() ?? pages.account.path);
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const onUploadPhoto = async (documentIn: IDocument) => {
        if (!isUploadedDocument(documentIn)) return;
        try {
            setCps(documentIn);
            setCpsPhoto(await getDocument(documentIn.id).catch((err: Error) => {
                throw err;
            }));
        }
        catch (err: any) {
            setMessage(err);
        }
    };

    const validateCodesFromCredentials = async (codesIn: ICodes, credentials: ILoginParams) => {
        const { email, password } = credentials;
        const session = await login({
            email,
            password,
        }).catch((err: Error) => {
            throw err;
        });

        const sessionUser = session.user;

        if (!sessionUser) {
            throw new Error('User undefined');
        }

        updateSession(session);

        await verifyTokens(sessionUser, codesIn, session).catch((err: Error) => {
            throw err;
        });
    };

    const validateCodesFromSession = async (codesIn: ICodes, session: ISession) => {
        const sessionUser = session.user;
        if (!sessionUser) {
            throw new Error('User undefined');
        }
        await verifyTokens(sessionUser, codesIn, session).catch((err: Error) => {
            throw err;
        });
    };

    const verifyTokens = async (sessionUser: IUser, codesIn: ICodes, session: ISession) => {
        if (!sessionUser.emailVerified) {
            await verifyCode({
                code: codesIn.emailCode,
            }, session).catch((err: Error) => {
                throw err;
            });
        }
    };

    const goNextStep = () => {
        setMessage(undefined);

        const nextStepIn = StepsWorkflow[step];
        if (nextStepIn) {
            setStep(nextStepIn);
        }
    };

    const onGoPreviousStep = () => {
        setMessage(undefined);

        const foundIndex = Object.values(StepsWorkflow).findIndex((value?: Step) => value === step);
        if (foundIndex !== -1) {
            setStep(foundIndex);
        }
    };

    return <RegisterComponent
        user={user}
        fillInformation={onFillInformation}
        validateCodes={onValidateCodes}
        searchRpps={onSearchRpps}
        message={message}
        goPreviousStep={onGoPreviousStep}
        step={step}
        cpsPhoto={cpsPhoto}
        uploadPhoto={onUploadPhoto}
        complete={onComplete}
        pscEnabled={constants.isProSanteConnectEnabled}
    />;
};

export default memo(Register);
