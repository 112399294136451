import AccountPage from 'components/account';
import BillingAddressPage from 'components/account/billingAddress';
import AccountsAdministration from 'components/admin/accounts';
import BillingPage from 'components/billing';
import ContactsPage from 'components/contacts';
import DocumentsPage from 'components/documents';
import ExpertisesChatPage from 'components/expertises/chat';
import ExpertisesPlaceholderPage from 'components/expertises/placeholder';
import ExpertisesRequestPage from 'components/expertises/request';
import ExpertsPage from 'components/experts';
import AuthenticationPage from 'components/home/authentication';
import NotificationPage from 'components/home/notification';
import PasswordResetPage from 'components/home/password-reset';
import ResetPage from 'components/home/reset';
import InvoicesPage from 'components/invoices';
import PatientsPage from 'components/patients';
import PaymentMethodsPage from 'components/payment-methods';
import PscPage from 'components/psc';
import Security from 'components/security';
import TasksPage from 'components/tasks';
import AuthenticationLayout from 'layouts/authentication';
import DefaultLayout from 'layouts/default';
import Loading from 'layouts/loading';
import ServicesLayout from 'layouts/services';
import pages from 'pages';
import { memo, ReactElement, Suspense, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useRoutes } from 'react-router-dom';
import { useAppActions } from 'redux/actions/app.action';
import { EmbeddedExpertise } from '../../pages/embedded/expertise';
import { withExpertisesAccess } from '../../services/helpers';
import Email from '../account/email';
import { Expertises } from '../expertises';
import './index.less';

const App = (): ReactElement => {
    const { constants, isConstantsLoaded } = useAppActions();

    useEffect(() => {
        if (!isConstantsLoaded) {
            return;
        }

        TagManager.initialize({
            gtmId: constants.googleGtmId ?? '',
        });
    }, [constants]);

    const routes = useRoutes([
        { path: pages.login.path, element: <AuthenticationLayout component={AuthenticationPage} /> },
        { path: pages.registration.path, element: <AuthenticationLayout component={AuthenticationPage} /> },
        { path: pages.registrationWithValidation.path, element: <AuthenticationLayout component={AuthenticationPage} /> },
        { path: pages.reset.path, element: <AuthenticationLayout component={ResetPage} /> },
        { path: pages.passwordReset.path, element: <AuthenticationLayout component={PasswordResetPage} /> },
        { path: pages.notification.path, element: <AuthenticationLayout component={NotificationPage} /> },
        { path: pages.account.path, element: <ServicesLayout component={AccountPage} /> },
        { path: pages.contacts.path, element: <ServicesLayout component={ContactsPage} /> },
        { path: pages.email.path, element: <ServicesLayout component={Email} /> },
        { path: pages.security.path, element: <ServicesLayout component={Security} /> },
        { path: pages.myDocuments.path, element: <ServicesLayout component={DocumentsPage} /> },
        { path: pages.documentsSharedWithMe.path, element: <ServicesLayout component={DocumentsPage} /> },
        { path: pages.experts.path, element: <ServicesLayout component={ExpertsPage} /> },
        { path: pages.billing.path, element: <ServicesLayout component={BillingPage} /> },
        { path: pages.invoices.path, element: <ServicesLayout component={InvoicesPage} /> },
        { path: pages.billingAddress.path, element: <ServicesLayout component={BillingAddressPage} /> },
        { path: pages.paymentMethods.path, element: <ServicesLayout component={PaymentMethodsPage} /> },
        { path: pages.tasks.path, element: <ServicesLayout component={TasksPage} /> },
        { path: pages.expertises.path, element: <ServicesLayout component={Expertises} /> },
        { path: pages.expertisesRequest.path, element: <ServicesLayout component={withExpertisesAccess(ExpertisesRequestPage)} /> },
        { path: pages.expertisesSentChat.path, element: <ServicesLayout component={ExpertisesChatPage} /> },
        { path: pages.expertisesReceivedChat.path, element: <ServicesLayout component={ExpertisesChatPage} /> },
        { path: pages.expertisesSent.path, element: <ServicesLayout component={ExpertisesChatPage} /> },
        { path: pages.expertisesReceived.path, element: <ServicesLayout component={ExpertisesChatPage} /> },
        { path: pages.expertisesPlaceholder.path, element: <ServicesLayout component={ExpertisesPlaceholderPage} /> },
        { path: pages.accountsAdministration.path, element: <ServicesLayout component={AccountsAdministration} /> },
        { path: pages.proSanteConnectCallback.path, element: <ServicesLayout component={AccountPage} /> },
        { path: pages.patients.path, element: <ServicesLayout component={PatientsPage} /> },
        { path: pages.proSanteConnectLogin.path, element: <DefaultLayout component={PscPage} /> },
        { path: pages.embeddedExpertise.path, element: <EmbeddedExpertise /> },
    ]);

    return <Suspense fallback={<Loading />}>
        {routes}
    </Suspense>;
};

export default memo(App);
