import jwt from 'jsonwebtoken';
import { ActionTypes, Type } from 'redux/actions/types';

const defaultState: ISession = {
    accessToken: undefined,
    refreshToken: undefined,
    user: undefined,
    redirectUrl: undefined,
};

let savedState: ISession = defaultState;

if (sessionStorage.getItem('sessionState')) {
    savedState = JSON.parse(sessionStorage.getItem('sessionState') as string);
    savedState.accessToken = handleToken(savedState, 'accessToken');
    savedState.refreshToken = handleToken(savedState, 'refreshToken');

}

function handleToken(state: ISession, tokenType: 'accessToken' | 'refreshToken'): string | undefined {
    if (tokenType in state && state[tokenType]) {
        const token = state[tokenType] as string;
        const tokenInfo = jwt.decode(token);
        if (!tokenInfo || (tokenInfo && typeof (tokenInfo) === 'object' && 'exp' in tokenInfo && tokenInfo.exp && tokenInfo.exp < Date.now() / 1000)) {
            return undefined;
        }
    }

    return state[tokenType];
}

const session = (state: ISession = savedState, action: ActionTypes): ISession => {
    let temporaryState: ISession = { ...state };
    switch (action.type) {
        case Type.INIT_SESSION:
            temporaryState = {
                ...temporaryState,
                ...action.payload,
            };
            saveState(temporaryState);
            break;
        case Type.RESET_SESSION:
            temporaryState = defaultState;
            saveState(temporaryState);
            break;
        case Type.SET_REDIRECT:
            temporaryState = {
                ...temporaryState,
                redirectUrl: action.payload,
            };
            saveState(temporaryState);
            break;
        default:
    }

    return temporaryState;
};

function saveState(state: ISession): void {
    sessionStorage.setItem('sessionState', JSON.stringify(state));
}

export default session;
