import { useDispatch, useSelector } from 'react-redux';
import { IInitSessionAction, IResetSessionAction, ISetRedirectAction, Type } from 'redux/actions/types';
import { AppState } from 'redux/reducers';
import Api from 'services/api';
import { useApiErrorHandling } from 'services/helpers';

// eslint-disable-next-line
export function useSessionActions() {
    const dispatch = useDispatch();

    const dispatchSessionAction = (sessionIn: ISession) => {
        dispatch<IInitSessionAction>({
            payload: sessionIn,
            type: Type.INIT_SESSION,
        });
    };

    const refreshSession = async (): Promise<void> => {
        dispatchSessionAction(await Api.refreshAccessToken(session).catch((err: Error) => {
            throw err;
        }));
    };

    const errorHandling = useApiErrorHandling(refreshSession);

    const mapStateToObj = useSelector((state: AppState) => {
        return {
            session: state.session,
        };
    });

    const { session } = mapStateToObj;

    return {
        isConnected: (): boolean => {
            return !!session.user;
        },
        updateSession: (sessionIn: ISession): void => {
            dispatchSessionAction(sessionIn);
        },
        updateSessionUser: (user: IUser): void => {
            dispatchSessionAction({
                ...session,
                user: {
                    ...session.user,
                    ...user,
                },
            });
        },
        setRedirectUrl: (url: string | undefined): void => {
            dispatch<ISetRedirectAction>({
                payload: url,
                type: Type.SET_REDIRECT,
            });
        },
        login: async (payload: ILoginParams): Promise<ISession> => {
            return Api.login(payload).catch((err: Error) => {
                throw err;
            });
        },
        proSanteConnectLogin: async (psctToken: string): Promise<ISession> => {
            const sessionIn = await Api.proSanteConnectLogin(psctToken).catch((err: Error) => {
                throw err;
            });

            dispatchSessionAction(sessionIn);

            return sessionIn;
        },
        register: async (payload: IRegisterParams): Promise<void> => {
            await Api.register(payload).catch((err: Error) => {
                throw err;
            });
        },
        requestPasswordReset: async (payload: IRequestResetParams): Promise<void> => {
            return Api.requestPasswordReset(payload).catch((err: Error) => {
                throw err;
            });
        },
        resetPassword: async (payload: IResetParams, token?: string): Promise<void> => {
            return Api.resetPassword(payload, token).catch((err: Error) => {
                throw err;
            });
        },
        searchRpps: async (payload: ISearchParams): Promise<IRpps[]> => {
            return Api.searchRpps(payload, session).catch(errorHandling);
        },
        getRpps: async (payload: IRppsGetParams): Promise<IUser> => {
            return Api.getRpps(payload, session).catch(errorHandling);
        },
        completeAccount: async (payload: IAccountParams): Promise<void> => {
            await Api.completeAccount(payload, session).catch(errorHandling);
        },
        getAccount: async (): Promise<IUser> => {
            return Api.getAccount(session).catch(errorHandling);
        },
        updateAccountSecurity: async (payload: IAccountSecurityParams): Promise<void> => {
            return Api.updateAccountSecurity(payload, session).catch(errorHandling);
        },
        requestEmailCode: async (payload: IRequestResetParams): Promise<void> => {
            return Api.requestEmailCode(payload, session).catch(errorHandling);
        },
        verifyCode: async (code: IValidationCodeParams, sessionIn: ISession): Promise<void> => {
            return Api.verifyCode(code, sessionIn).catch((err: Error) => {
                throw err;
            });
        },
        validateCode: async (code: IValidationCodeParams, sessionIn: ISession): Promise<void> => {
            return Api.validateCode(code, sessionIn).catch((err: Error) => {
                throw err;
            });
        },
        verifyAccessToken: async (): Promise<void> => {
            return Api.verifyAccessToken(session).catch((err: Error) => {
                throw err;
            });
        },
        refreshSession,
        sendToken: async (sessionIn: ISession): Promise<void> => {
            return Api.sendToken(sessionIn).catch((err: Error) => {
                throw err;
            });
        },
        logout: () => {
            dispatch<IResetSessionAction>({
                type: Type.RESET_SESSION,
            });
        },
        getUsers: async (payload: ISearchParams): Promise<IPaginatedResults<IUser>> => {
            return Api.getUsers(payload, session).catch(errorHandling);
        },
        getContacts: async (payload: ISearchParams): Promise<IPaginatedResults<IContact>> => {
            return Api.getContacts(payload, session).catch(errorHandling);
        },
        getInvoices: async (payload: IQuoteSearchParams): Promise<IPaginatedResults<IInvoice>> => {
            return Api.getInvoices(payload, session).catch(errorHandling);
        },
        getInvoicesCsv: async (payload: IQuoteSearchParams): Promise<Buffer> => {
            return Api.getInvoicesCsv(payload, session).catch(errorHandling);
        },
        printInvoice: async (payload: number): Promise<Buffer> => {
            return Api.printInvoice(payload, session).catch(errorHandling);
        },
        getUserInvoices: async (payload: ISearchParams): Promise<IPaginatedResults<IUserInvoice>> => {
            return Api.getUserInvoices(payload, session).catch(errorHandling);
        },
        refreshUserInvoice: async (id: number): Promise<void> => {
            return Api.refreshUserInvoice(id, session).catch(errorHandling);
        },
        generateUserInvoices: async (): Promise<void> => {
            return Api.generateUserInvoices(session).catch(errorHandling);
        },
        setupPaymentMethod: async (): Promise<ISetupPayment> => {
            return Api.setupPayment(session).catch(errorHandling);
        },
        getPaymentMethods: async (): Promise<IPaymentMethod[]> => {
            return Api.getPaymentMethods(session).catch(errorHandling);
        },
        getPersons: async (payload: ISearchParams): Promise<IPerson[]> => {
            return Api.getPersons(payload, session).catch(errorHandling);
        },
        getPatients: async (payload: ISearchPatientParams): Promise<IPaginatedResults<IPatient>> => {
            return Api.getPatients(payload, session).catch(errorHandling);
        },
        getUser: async (userId: IUser['id']): Promise<IUser> => {
            return Api.getUser(userId, session).catch(errorHandling);
        },
        getNotification: async (notificationId: string, token: string, force: boolean): Promise<INotification> => {
            return Api.getNotification(notificationId, token, force, session).catch(errorHandling);
        },
        isAdmin: (): boolean => {
            return !!session.user?.roles?.includes('ADMIN');
        },
        getRedirectUrl: () => session.redirectUrl,
    };
}
